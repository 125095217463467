import { connect } from "react-redux";
import React, { useEffect } from "react";
import PickupDate from "../../Modals/PickupDate";
import DelivaryDate from "../../Modals/DelivaryDate";
import PickupSlot from "../../Modals/Slots/Pickup";
import DeliverySlot from "../../Modals/Slots/Delivery";
import { useTranslation } from "react-i18next";

const PickupDelivery = (props) => {
  // useEffect(() => {}, [props?.sAddress]);
  const { t } = useTranslation();

  function formatDate(apiDate) {
    const dateObj = new Date(apiDate + " 00:00:00");

    const daysOfWeek = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
    const dayOfWeek = daysOfWeek[dateObj.getUTCDay()];

    const day = String(dateObj.getUTCDate() + 1).padStart(2, '0');
    const month = String(dateObj.getUTCMonth() + 1).padStart(2, '0');

    return `${dayOfWeek} - ${day}/${month}`;
  }

  const apiDate = props?.pickUpDateDelivery;
  const formattedDate = formatDate(apiDate);
  console.log(formattedDate, 'formatted date');

  // const slot = props?.pickUpSlotDelivery;
  // const [startTime, endTime] = slot.split(':00');
  // const formattedSlot = `${startTime}h${endTime}h`;

  return (
    <>
      {props?.sAddress && props?.address ? (
        <>
          <div className="row">
            <div className="col-md-6">
              <h4 className="mb-3 theme_color text-center fw_medium">{t("pick-up")}</h4>
            </div>
            <div className="col-md-6 display_none_mobile">
              <h4 className="mb-3 theme_color text-center fw_medium">{t("delivery")}</h4>
            </div>
          </div>
          <div className="row mb-4">
            <div className="col-md-6">
              <div
                onclick="modalblur()"
                data-bs-toggle={!props?.editDelivery && "modal"}
                data-bs-target={!props?.editDelivery && "#pickup_date"}
                className={`${props?.pTime || props?.pickupTime || props?.editDelivery ? 'theme_bg white' : 'theme_border border-2 theme_color'} h-100 rounded p-3 d-flex flex-row justify-content-between align-items-center ${!props?.editDelivery && "pickupDeliver"}`}
              >
                <div className="d-flex flex-column">
                  <span className="fw_medium">
                    {props.editDelivery ? formattedDate :
                      props?.pTime
                        ?
                        `${props?.pTime?.date?.day.slice(0, 3)} - ${props?.pTime?.date?.date?.split("-")[2] +
                        "/" +
                        props?.pTime?.date?.date?.split("-")[1]
                        }`
                        : `${t("select pick-up")}
                      `}
                  </span>
                  <span className="small fw_regular">{`${props?.editDelivery ? props?.pickUpSlotDelivery :
                    props?.pickupTime
                      ? props?.pickupTime
                      : `${t("please select time")}`
                    }`}</span>
                </div>
                {props?.pTime || props?.pickUpDateDelivery ? <img alt="" className="ms-2" src="/assets/imgs/calander-white.svg" /> : <img alt="" className="ms-2" src="/assets/imgs/calander.png" />}
              </div>
            </div>
            <div className="display_none_descktop">
              <h4 className="my-3 theme_color text-center">{t("delivery")}</h4>
            </div>
            <div className="col-md-6">
              <div
                onclick="modalblur()"
                style={{ cursor: "pointer" }}
                data-bs-toggle="modal"
                data-bs-target="#delivery_date"
                className={`${props?.dTime || props?.deliveryTime ? 'theme_bg white' : 'theme_border border-2 theme_color'} h-100 rounded p-3 d-flex flex-row justify-content-between align-items-center pickupDeliver`}
              >
                <div className="d-flex flex-column">
                  <span className="fw_medium">
                    {props?.dTime
                      ? `${props?.dTime?.date?.day.slice(0, 3)} - ${props?.dTime?.date?.date?.split("-")[2] +
                      "/" +
                      props?.dTime?.date?.date?.split("-")[1]
                      }`
                      : `${t("select delivery")}`}
                  </span>
                  <span className="small fw_regular">{`${props?.deliveryTime
                    ? props?.deliveryTime
                    : `${t("please select time")}`
                    }`}</span>
                </div>
                {props?.dTime || props?.deliveryTime ? <img alt="" className="ms-2" src="/assets/imgs/calander-white.svg" /> : <img alt="" className="ms-2" src="/assets/imgs/calander.png" />}
              </div>
            </div>
          </div>
          <PickupDate />
          <DelivaryDate />
        </>
      ) : (
        <>
          <div className="row">
            <div className="col-md-6">
              <h4 className="mb-3 theme_color text-center">{t("pick-up")}</h4>
            </div>
            <div className="col-md-6 display_none_mobile">
              <h4 className="mb-3 theme_color text-center">{t("delivery")}</h4>
            </div>
          </div>
          <div className="row mb-4">
            <div className="col-md-6">
              <div
                onclick="modalblur()"
                style={{ cursor: "pointer" }}
                data-bs-toggle="modal"
                data-bs-target="#pickup_slot"
                className={`${props?.pTime || props?.pickupTime || props?.editDelivery ? 'theme_bg white' : 'theme_border border-2 theme_color'} h-100 rounded p-3 d-flex flex-row justify-content-between align-items-center pickupDeliver`}
              >
                <div className="d-flex flex-column">
                  <span className="fw_medium">
                    {props.editDelivery ? formattedDate : props?.pTime
                      ? `${t(props?.pTime?.date?.day.slice(0, 3))} - ${props?.pTime?.date?.date?.split("-")[2] +
                      "/" +
                      props?.pTime?.date?.date?.split("-")[1]
                      }`
                      : `${t("select pick-up")}`}
                  </span>
                  <span className="small fw_regular">{`${props?.editDelivery ? props?.pickUpSlotDelivery : 
                  props?.pickupTime
                    ? props?.pickupTime
                    : `${t("please select time")}`
                    }`}</span>
                </div>
                <img alt="" className="ms-2" src="/assets/imgs/calander.png" />
              </div>
            </div>
            <div className="display_none_descktop">
              <h4 className="my-3 theme_color text-center">{t("delivery")}</h4>
            </div>
            <div className="col-md-6">
              <div
                onclick="modalblur()"
                data-bs-toggle="modal"
                style={{ cursor: "pointer" }}
                data-bs-target="#delivery_slot"
                className={`${props?.dTime || props?.deliveryTime ? 'theme_bg white' : 'theme_border border-2 theme_color'} h-100 rounded p-3 d-flex flex-row justify-content-between align-items-center pickupDeliver`}
              >
                <div className="d-flex flex-column">
                  <span className="fw_medium">
                    {props?.dTime
                      ? `${t(props?.dTime?.date?.day.slice(0, 3))} - ${props?.dTime?.date?.date?.split("-")[2] +
                      "/" +
                      props?.dTime?.date?.date?.split("-")[1]
                      }`
                      : `${t("select delivery")}`}
                  </span>
                  <span className="small fw_regular">{`${props?.deliveryTime
                    ? props?.deliveryTime
                    : `${t("please select time")}`
                    }`}</span>
                </div>
                <img alt="" className="ms-2" src="/assets/imgs/calander.png" />
              </div>
            </div>
          </div>
          <PickupSlot />
          <DeliverySlot />
        </>
      )}
    </>
  );
};
const mapStateToProps = (state) => ({
  editDelivery: state.auth.edit,
  pickUpDateDelivery: state.auth.pickUpDateDelivery,
  pickUpSlotDelivery: state.auth.pickUpSlotDelivery,
  cartItems: state.cart.cartItems,
  totalAmount: state.cart.totalAmount,
  address: state.cart.home,
  deliveryAddress: state.auth.homeAddress,
  dTime: state?.cart?.time,
  pTime: state?.cart?.pickup,
  pCode: state?.cart?.promo,
  sAddress: state?.auth?.selectedAddress,
  pickupTime: state?.auth?.pickupTime,
  deliveryTime: state?.auth?.deliveryTime,
  paymentMethod: state?.auth?.paymentMethod,
  paymentId: state?.auth?.paymentId,
  renderCheck: state?.auth?.render,
});
export default connect(mapStateToProps)(PickupDelivery);
