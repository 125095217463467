import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import "../../Header/user.css";
import Config from "../../../Config/index";
import Axios from "axios";
import ProgressStatus from "../ProgressStatus";
import MobileProgress from "../ProgressStatus/MobileProgress";
import PastOrder from "./PastOrders/index";
import cookies from "js-cookie";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { compose } from "redux";
import { setCurrent, setCurrentId, setEdit, setEditOrder, setPaymentCard, setPaymentMethod, setPickUpDateDelivery, setPickUpIssueFix, setPickUpSlotDelivery, setSelectedAddress } from "../../../../store/Actions/authActions";
import OrderErrorDetails from "./OrderErrorDetails";
import { cartApi, cartEdit, fetchCartItem, selectAddress } from "../../../../store/Actions/cartActions";
import WoshWalletTracking from "../../Modals/WoshWalletTracking";
import OrderDetailsModal from "./ViewOrderDetails";

class TabContent extends React.Component {
  constructor() {
    super();
    this.state = {
      dropDown: null,
      rating: 0,
      error: true,
      index: 0,
      current: [],
      paginationNumbers: 0,
      past: null,
      step: "",
      currentTab: 0,
      deleteOrderModal: false,
      isAddCreditModalOpen: false,
      showOrderDetailsModal: false,
      currentOrder: null,
      wallet: null,
    }
  }

  async componentDidMount() {
    try {
      const response = await Axios({
        url: `${Config.apiURL}/customer/orders`,
        method: "GET",
        headers: {
          uid: this.props?.authUser?.user_uid,
          client: this.props?.authUser?.user_client,
          "access-token": this.props?.authUser?.user_access_token,
        },
      });
      response.data &&
        this.props?.dispatch(
          setCurrent(response.data.current_orders?.products)
        );

      this.setState({
        current: response.data.current_orders || [],
        error: false,
        // paginationNumbers: response.data.current_orders.length > 3 ? ['0', '1', '3'] : response.data.current_orders
      });
    } catch (error) {
      this.setState({ error: true });
    }
    await this.getWallet();
  }

  getWallet = async () => {
    try {
      const response = await Axios({
        url: `${Config.apiURL}/customer/wallet`,
        method: 'get',
        headers: {
          uid: this.props?.authUser?.user_uid,
          client: this.props?.authUser?.user_client,
          "access-token": this.props?.authUser?.user_access_token,
        },
      });
      const amount = response?.data?.wallet?.amount;
      this.setState({ wallet: amount });
    } catch (err) {
      console.error('Error fetching wallet data:', err);
    }
  };

  handleViewOrderDetails = (order, show) => {
    this.setState({
      showOrderDetailsModal: show,
      currentOrder: show ? order : null
    });
  };

  handleCloseModal = () => {
    this.setState({
      showOrderDetailsModal: false,
      currentOrder: null,
    });
  };

  handleDeleteOrder = async (id) => {
    try {
      const response = await Axios({
        url: `${Config.apiURL}/customer/orders/${id}`,
        method: "DELETE",
        headers: {
          uid: this.props?.authUser?.user_uid,
          client: this.props?.authUser?.user_client,
          "access-token": this.props?.authUser?.user_access_token,
        },
      });
      if (response.status === 200) {
        console.log('Order deleted successfully.');
        this.setState((prevState) => ({
          current: prevState.current.filter((item) => item.id !== id),
        }));
        this.setState({
          deleteOrderModal: false,
        }, () => {
          window.location.reload();
        })
      } else {
        console.error('Failed to delete the order.');
        this.setState({ error: true });
      }
    } catch (error) {
      console.error("Error deleting order:", error);
      this.setState({ error: true });
    }
  }

  handleOpenAddCreditModal = () => {
    this.setState({ isAddCreditModalOpen: true });
  };

  handleCloseAddCreditModal = () => {
    this.setState({ isAddCreditModalOpen: false });
  };

  handleEditDelivery = async (current, editOrder) => {
    let array = current?.products?.map((item) => {
      const result = this.props?.items?.find(
        (product) => product.id === item.product_id && product?.is_active
      );
      if (result?.is_active) {
        return {
          ...result,
          quantity: item?.quantity,
          price: +result?.price * item?.quantity,
          table_id: item?.id,
          order_id: item?.order_id,
        };
      }
    });

    let final = array.filter((item) => item !== undefined);

    let type = current?.collection_point_id ? "office" : "home";
    if (type === "home") {
      this.props?.dispatch(setSelectedAddress(`${current?.delivery_address_id}`, type));
      this.props?.dispatch(selectAddress(type));
    } else {
      this.props?.dispatch(setSelectedAddress(`${current?.collection_point_id}`, "office"));
      this.props?.dispatch(selectAddress(type));
    }

    this.props?.dispatch(setCurrentId(current?.id));
    this.props?.dispatch(setCurrent(current?.products));
    this.props?.dispatch(setEditOrder(!editOrder))
    this.props?.dispatch(setEdit(editOrder))
    this.props?.dispatch(setPickUpIssueFix(!editOrder))
    this.props?.dispatch(setPickUpDateDelivery(current.pickup_date));
    this.props?.dispatch(setPickUpSlotDelivery(current.pickup_slot));
    this.props?.dispatch(
      setPaymentCard(
        current?.payment_method_id ? current?.payment_method_id : null
      )
    );
    this.props?.dispatch(
      setPaymentMethod(
        current?.payment_method_id ? "Debit Card" : "Wallet"
      )
    );

    await this.synchronizeCart(current);
    this.props?.history?.push("/checkout");
  };

  handleEditPickupIssue = async (current, editOrder) => {
    let array = current?.products?.map((item) => {
      const result = this.props?.items?.find(
        (product) => product.id === item.product_id && product?.is_active
      );
      if (result?.is_active) {
        return {
          ...result,
          quantity: item?.quantity,
          price: +result?.price * item?.quantity,
          table_id: item?.id,
          order_id: item?.order_id,
        };
      }
    });

    let final = array.filter((item) => item !== undefined);

    let type = current?.collection_point_id ? "office" : "home";
    if (type === "home") {
      this.props?.dispatch(setSelectedAddress(`${current?.delivery_address_id}`, type));
      this.props?.dispatch(selectAddress(type));
    } else {
      this.props?.dispatch(setSelectedAddress(`${current?.collection_point_id}`, "office"));
      this.props?.dispatch(selectAddress(type));
    }

    this.props?.dispatch(setCurrentId(current?.id));
    this.props?.dispatch(setCurrent(current?.products));
    this.props?.dispatch(setEditOrder(editOrder))
    this.props?.dispatch(setEdit(!editOrder))
    this.props?.dispatch(setPickUpIssueFix(editOrder))
    this.props?.dispatch(
      setPaymentCard(
        current?.payment_method_id ? current?.payment_method_id : null
      )
    );
    this.props?.dispatch(
      setPaymentMethod(
        current?.payment_method_id ? "Debit Card" : "Wallet"
      )
    );

    await this.synchronizeCart(current);
    this.props?.history?.push("/checkout");
  };

  synchronizeCart = async (current) => {
    try {
      await this.props.dispatch(fetchCartItem());

      await Promise.all(
        this.props?.cartItems?.map((p) =>
          this.props?.dispatch(cartApi(p, "destroyAllProducts"))
        )
      );

      await this.props?.dispatch(cartEdit(current, this.props?.history));
      await this.props.dispatch(fetchCartItem());
    } catch (error) {
      console.error("Error synchronizing cart:", error);
    }
  };

  handleDropDown = (value) => {
    this.setState({
      dropDown: value,
    });
  };

  onStarClick(nextValue, prevValue, name) {
    this.setState({ rating: nextValue });
  }

  handleTabs = (value) => {
    this.setState({
      currentTab: value,
    });
  };

  editStyle = {
    width: '100%',
    maxWidth: '230px',
    background: '#3d4586',
    color: '#ffffff',
  }

  render() {
    const { t } = this.props;
    console.log(this.state.current[this.state.currentTab], 'current tab')
    return (
      <div
        className={`${this.state.isAddCreditModalOpen ? "black" : ""
          } "container"`}
      >
        <div className="tab-content" id="myTabContent">
          <div
            className={
              this.props.myParam
                ? "tab-pane fade py-4 flex-sm-column flex-row order_page"
                : "tab-pane fade show active py-4 flex-sm-column flex-row order_page"
            }
            id="body-laundry"
            role="tabpanel"
            aria-labelledby="home-tab"
          >
            {this.state.current && this.state.current?.length > 0 && (
              <div className="container overflow-hidden">
                <ul
                  className={
                    this.state.current?.length <= 5
                      ? "nav nav-tabs second_tabs order_tabs scroll_horizon widthFitContent mx-auto"
                      : "nav nav-tabs second_tabs order_tabs scroll_horizon"
                  }
                  id="myTab"
                  role="tablist"
                >
                  {this.state.current && this.state.current?.length > 0
                    ? this.state.current?.map((current, index) => {
                      return (
                        <li
                          key={index}
                          className="nav-item bg-white"
                          role="presentation"
                        >
                          <a
                            className={
                              index == this.state.currentTab
                                ? "nav-link px-4 py-2 h-100 active"
                                : "min_width nav-link px-4 py-2 h-100"
                            }
                            onClick={() => {
                              this.handleTabs(index)
                            }}
                          >
                            <h3 className="fs-6 mb-0">
                              {this.props.t("order") + " " + (1 + index)}
                            </h3>
                          </a>
                        </li>
                      )
                    })
                    : null}
                </ul>
              </div>
            )}
            {this.state.current && this.state.current?.length === 0 ? (
              <h6 className="theme_color text-center">
                {" "}
                {this.props.t("No Current Order")}
              </h6>
            ) : (
              <>
                {this.state.current && this.state.current.length > 0 ? (
                  <div>
                    <ProgressStatus
                      current={this.state.current[this.state.currentTab]}
                      step={
                        this.state.current[this.state.currentTab]?.status ===
                          "confirmed" &&
                          !this.state.current[this.state.currentTab].pick_up_issue
                          ? 1
                          : this.state.current[this.state.currentTab]
                            ?.status === "confirmed" &&
                            this.state.current[this.state.currentTab]
                              .pick_up_issue
                            ? 2
                            : this.state.current[this.state.currentTab]
                              ?.status === "picked_up" ||
                              this.state.current[this.state.currentTab]
                                ?.status === "dropped_at_laundry"
                              ? 2
                              : this.state.current[this.state.currentTab]
                                ?.status === "processed"
                                ? 3
                                : this.state.current[this.state.currentTab]
                                  ?.status === "ready_for_delivery" &&
                                  !this.state.current[this.state.currentTab]
                                    .delivery_issue
                                  ? 4
                                  : this.state.current[this.state.currentTab]
                                    ?.status === "ready_for_delivery" &&
                                    this.state.current[this.state.currentTab]
                                      .delivery_issue
                                    ? 5
                                    : this.state.current[this.state.currentTab]
                                      ?.status === "delivered"
                                      ? 5
                                      : ""
                      }
                    />
                    <MobileProgress
                      current={this.state.current[this.state.currentTab]}
                      step={
                        this.state.current[this.state.currentTab]?.status ===
                          "confirmed"
                          ? 1
                          : this.state.current[this.state.currentTab]
                            ?.status === "picked_up" ||
                            this.state.current[this.state.currentTab]
                              ?.status === "dropped_at_laundry"
                            ? 2
                            : this.state.current[this.state.currentTab]
                              ?.status === "processed"
                              ? 3
                              : this.state.current[this.state.currentTab]
                                ?.status === "ready_for_delivery" &&
                                !this.state.current[this.state.currentTab]
                                  .delivery_issue
                                ? 4
                                : this.state.current[this.state.currentTab]
                                  ?.status === "ready_for_delivery" &&
                                  this.state.current[this.state.currentTab]
                                    .delivery_issue
                                  ? 5
                                  : this.state.current[this.state.currentTab]
                                    ?.status === "delivered"
                                    ? 5
                                    : ""
                      }
                      history={this.props.history}
                    />
                    <div className="mt-5">
                      {this.state.current[this.state.currentTab]
                        ?.pick_up_issue ? (
                        <>
                          {this.state.deleteOrderModal ? (
                            <OrderErrorDetails
                              btnStyle={this.editStyle}
                              mainHeading={`${t("We missed you!")}😢`}
                              subHeading={
                                t("Are you sure you want to delete your order?")
                              }
                              detail={t("You can always place a new one later!")}
                              btnText={t("Yes, delete order")}
                              primaryOnclick={() => {
                                const currentItem =
                                  this.state.current[this.state.currentTab]
                                if (currentItem && currentItem.id) {
                                  this.handleDeleteOrder(currentItem.id)
                                } else {
                                  console.error("Invalid ID for deletion.")
                                }
                              }}
                              secondaryBtnType={"btn"}
                              secondaryBtnText={t("No, don’t delete order")}
                              secondaryBtnOnclick={() => {
                                this.setState({ deleteOrderModal: false })
                              }}
                            />
                          ) : (
                            <>
                              <OrderErrorDetails
                                btnStyle={this.editStyle}
                                mainHeading={`${t("We missed you!")}😢`}
                                subHeading={
                                  t("Our driver informed us that they were unable to retrieve your order during their visit.")
                                }
                                detail={
                                  t("Please select a new pick-up moment or delete your order.")
                                }
                                btnText={t("Schedule new pick up")}
                                primaryOnclick={() =>
                                  this.handleEditPickupIssue(
                                    this.state.current[this.state.currentTab],
                                    true
                                  )
                                }
                                secondaryBtnType={"link"}
                                secondaryBtnText={t("Delete Order")}
                                linkOnclick={() => {
                                  this.setState({ deleteOrderModal: true })
                                }}
                                viewOrder={
                                  <button
                                    className="bg-white border-0 theme_color"
                                    // onClick={() => this.handleViewOrderDetails(this.state.current[this.state.currentTab])}
                                    onMouseEnter={() => this.handleViewOrderDetails(this.state.current[this.state.currentTab], true)}
                                    onMouseLeave={() => this.handleViewOrderDetails(null, false)}
                                  >
                                    {t("View Order")}
                                  </button>
                                }
                              />
                              <OrderDetailsModal
                                show={this.state.showOrderDetailsModal}
                                // onClose={this.handleCloseModal}
                                toggle={() => this.handleViewOrderDetails(null, false)}
                                order={this.state.current[this.state.currentTab]}
                                t={t}
                              />
                            </>
                          )}
                        </>
                      ) : this.state.current[this.state.currentTab]
                        ?.payment_status === "failed" ? (
                        <div className="position-relative">
                          <OrderErrorDetails
                            btnStyle={this.editStyle}
                            mainHeading={t("Payment Issue")}
                            subHeading={
                              t("Card declined or insufficient amount in your WOSH wallet")
                            }
                            detail={
                              t("To maintain the uninterrupted quality of our service, we kindly request you to settle the outstanding amount promptly. Your prompt action ensures a seamless expereince wiht WOSH.")
                            }
                            btnText={t("Add credit to your wallet")}
                            primaryOnclick={() => {
                              this.handleOpenAddCreditModal()
                            }}
                          />
                          {this.state.isAddCreditModalOpen && (
                            <WoshWalletTracking
                              amount={this.state.wallet}
                              total={this.props?.totalAmount}
                              onClose={this.handleCloseAddCreditModal}
                              t={t}
                            />
                          )}
                        </div>
                      ) : this.state.current[this.state.currentTab]
                        ?.delivery_issue ? (
                        <>
                          <OrderErrorDetails
                            btnStyle={this.editStyle}
                            mainHeading={`${t("We missed you!")}😢`}
                            subHeading={
                              t("Our driver informed us that they were unable to retrieve your order during their visit.")
                            }
                            detail={
                              t("Please select a new delivery moment when it suits you best.")
                            }
                            btnText={t("Schedule new Delivery")}
                            primaryOnclick={() =>
                              this.handleEditDelivery(
                                this.state.current[this.state.currentTab],
                                true
                              )
                            }
                          />
                        </>
                      ) : (
                        <div className="order_tracking_items mx-auto p-4 overflow-hidden">
                          <ul className="m-0">
                            {this.state.current.map((order, index) => {
                              const formattedsubTotal = parseFloat(
                                order.subtotal
                              )
                              const formattedTotal = parseFloat(
                                formattedsubTotal.toFixed(2)
                              )
                              const promotionTotal = "0.00"
                              const sumTotal =
                                formattedTotal +
                                (order?.service_fee ? order?.service_fee : 0) -
                                promotionTotal
                              const finalTotal = sumTotal.toFixed(2)
                              const taxIncluded = (0.21 * sumTotal).toFixed(2)
                              if (index === this.state.currentTab) {
                                return (
                                  <div className="row">
                                    <div className="col-md-6">
                                      <div key={order.id}>
                                        <h3
                                          className={
                                            cookies.get("i18next") === "en"
                                              ? "order_id_style_en fw-bold mb-4"
                                              : cookies.get("i18next") === "fn"
                                                ? "order_id_style_fn fw-bold mb-4"
                                                : cookies.get("i18next") === "du"
                                                  ? "order_id_style_du fw-bold mb-4"
                                                  : "order_id_style_en fw-bold mb-4"}
                                        >
                                          {t("order")} {order.wosh_order_id}
                                        </h3>
                                        {order.products.map((product) => {
                                          const itemTotal =
                                            product?.product_price *
                                            product?.quantity
                                          const formattedTotal =
                                            itemTotal.toFixed(2)
                                          return (
                                            <li
                                              key={product.id}
                                              className="row"
                                              style={{
                                                color: "#3D4586",
                                                fontSize: "14px",
                                                fontWeight: 400,
                                              }}
                                            >
                                              <div className="col-5">
                                                <p className="m-0 small line_height">
                                                  {cookies.get("i18next") === "en"
                                                    ? product?.product_name_en
                                                    : cookies.get("i18next") === "fn"
                                                      ? product?.product_name
                                                      : cookies.get("i18next") === "du"
                                                        ? product?.product_name_nl
                                                        : product?.product_name_en}
                                                </p>
                                                <p className="small">
                                                  {product.product_price} €
                                                </p>
                                              </div>
                                              <div className="col-3">
                                                <p className="d-flex justify-content-evenly align-items-center m-0">
                                                  {product.quantity}
                                                </p>
                                              </div>
                                              <div className="col-4">
                                                <p className="m-0 text-end">
                                                  {formattedTotal} €
                                                </p>
                                              </div>
                                            </li>
                                          )
                                        })}
                                      </div>
                                      <hr
                                        className="w-100"
                                        style={{
                                          border: "1px solid #E7E9FF",
                                          margin: "20px auto 20px",
                                          opacity: 0.9,
                                        }}
                                      ></hr>
                                      <ul
                                        className="list-unstyled w-100 mx-auto"
                                        style={{
                                          fontFamily: "Galano Grotesque Alt",
                                          fontSize: "14px",
                                          fontWeight: 400,
                                          lineHeight: "16.2px",
                                          textAlign: "left",
                                          color: "#3D4586",
                                        }}
                                      >
                                        <li className="d-flex flex-row justify-content-between mb-2">
                                          <span>{t("Service fee")}</span>
                                          <span>
                                            {order.service_fee
                                              ? order.service_fee
                                              : "0,00"}{" "}
                                            €
                                          </span>
                                        </li>
                                        <li className="d-flex flex-row justify-content-between mb-2">
                                          <span>{t("Subtotal")}</span>
                                          <span>
                                            {formattedTotal.toFixed(2)} €
                                          </span>
                                        </li>
                                        <li className="d-flex flex-row justify-content-between">
                                          <span>{t("Promotions")}</span>
                                          <span>{promotionTotal} €</span>
                                        </li>
                                      </ul>
                                      <hr
                                        className="w-100"
                                        style={{
                                          border: "1px solid #E7E9FF",
                                          margin: "20px auto 30px",
                                          opacity: "0.9",
                                        }}
                                      ></hr>
                                      <div
                                        className="d-flex flex-row justify-content-between mx-auto"
                                        style={{
                                          fontFamily: "Galano Grotesque Alt",
                                          fontSize: "18px",
                                          fontWeight: 500,
                                          lineHeight: "24.3px",
                                          color: "#3D4586",
                                        }}
                                      >
                                        <span>Total</span>
                                        <span
                                          style={{
                                            fontFamily: "Galano Grotesque Alt",
                                            fontSize: "12px",
                                            fontWeight: 400,
                                            lineHeight: "16.2px",
                                            color: "#3D4586",
                                          }}
                                        >
                                          (tax incl. {taxIncluded}€)
                                        </span>
                                        <span>{finalTotal} €</span>
                                      </div>
                                    </div>
                                    <div className="col-md-1"></div>
                                    <div className="col-md-5">
                                      <h3 className="checkout-pickup-outer-title">
                                        {t("pick-up")}
                                      </h3>
                                      <div className="checkout-pickup">
                                        <div className="checkout-pickup-title d-flex justify-content-center align-items-center">
                                          <p className="checkout-heading m-0">
                                            {order.type
                                              ? order.type
                                              : order.wosh_order_id}
                                          </p>
                                        </div>
                                        <div className="d-flex flex-column justify-content-center align-items-center p-3">
                                          <p>{order.pickup_date}</p>
                                          <p className="m-0">
                                            {order.pickup_slot}
                                          </p>
                                        </div>
                                      </div>
                                      <h3 className="checkout-pickup-outer-title mt-4">
                                        {t("Delivery")}
                                      </h3>
                                      <div className="checkout-pickup">
                                        <div className="checkout-pickup-title d-flex justify-content-center align-items-center">
                                          <p className="checkout-heading m-0">
                                            {order.type
                                              ? order.type
                                              : order.wosh_order_id}
                                          </p>
                                        </div>
                                        <div className="d-flex flex-column justify-content-center align-items-center p-3">
                                          <p>{order.delivery_date}</p>
                                          <p className="m-0">
                                            {order.delivery_slot}
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                )
                              }
                              return null
                            })}
                          </ul>
                        </div>
                      )}
                    </div>
                  </div>
                ) : null}
              </>
            )}
          </div>
          <div
            className={
              this.props.myParam
                ? "tab-pane fade show active py-4 flex-sm-column flex-row order_page"
                : "tab-pane fade py-4"
            }
            id="home-laundry"
            role="tabpanel"
            aria-labelledby="profile-tab"
          >
            <PastOrder history={this.props?.history} />
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    authUser: state?.auth?.user,
    items: state?.cart?.mockItems,
    cartItems: state?.cart?.cartItems,
    totalAmount: state?.cart?.totalAmount
  };
};

export default compose(withTranslation(), connect(mapStateToProps))(TabContent);
