import React, { useState, useEffect } from "react";
import {
  setDTime,
  setPTime,
} from "../../../../../../store/Actions/authActions";
import { connect } from "react-redux";
import moment from "moment";
import axios from "axios";
import Config from "../../../../../Config";

const SlotRange = (props) => {
  const [range, setRange] = useState([
    {
      id: 1,
      value: "09:00 - 10:00",
    },
    {
      id: 2,
      value: "10:00 - 11:00",
    },
    {
      id: 3,
      value: "11:00 - 12:00",
    },
    {
      id: 4,
      value: "12:00 - 13:00",
    },
    {
      id: 5,
      value: "13:00 - 14:00",
    },
    {
      id: 6,
      value: "14:00 - 15:00",
    },
    {
      id: 7,
      value: "15:00 - 16:00",
    },
    {
      id: 8,
      value: "16:00 - 17:00",
    },
    {
      id: 9,
      value: "17:00 - 18:00",
    },
    {
      id: 10,
      value: "18:00 - 19:00",
    },
    {
      id: 11,
      value: "19:00 - 20:00",
    },
    {
      id: 12,
      value: "20:00 - 21:00",
    },
    {
      id: 13,
      value: "21:00 - 22:00",
    },
    {
      id: 14,
      value: "22:00 - 23:00",
    },
    {
      id: 15,
      value: "23:00 - 24:00",
    },
  ]);

  const [id, setId] = useState(null);
  const [rangeArray, setRangeArray] = useState([]);
  const [error, setError] = useState(false)
  const [errorMessage, setErrorMessage] = useState([])

  const todayDate = moment().format("DD/MM");
  const todayHour = moment().add(2, "hours").format("HH:mm");

  useEffect(() => {
    let array = [];
    for (let i = 0; i < 3; i++) {
      array[i] = range[i];
    }

    setRangeArray(array);
  }, [props?.sAddress]);

  const handleNext = () => {
    if (!rangeArray.at(-1) || rangeArray.at(-1).id === 15) {
      let array = [];
      for (let i = 0; i < 3; i++) {
        array[i] = range[i];
      }

      setRangeArray(array);
    } else {
      let array = [];
      for (let i = rangeArray.at(-1).id; i < rangeArray.at(-1).id + 3; i++) {
        array[i] = range[i];
      }

      setRangeArray(array);
    }
  };
  const prev = () => {
    // debugger;
    if (!rangeArray.at(-1) || rangeArray.at(-1).id === 3) {
      let y = 12;
      let temp = 0;
      let array = [];
      for (let i = 14; i >= 12; i--) {
        array[temp] = range[y];
        temp = temp + 1;
        y = y + 1;
        // console.log("range[i]", range[i]);
      }
      setRangeArray(array);
    } else if (rangeArray.at(-1) && rangeArray.at(-1).id === 15) {
      let temp = 0;
      let array = [];
      let y = 9;
      for (
        let i = rangeArray.at(-1).id - 4;
        i > rangeArray.at(-1).id - 7;
        i--
      ) {
        array[temp] = range[y];
        temp = temp + 1;
        y = y + 1;
        // console.log("range[i]", range[i]);
      }
      setRangeArray(array);
    } else if (rangeArray.at(-1) && rangeArray.at(-1).id === 4) {
      let array = [];
      for (let i = 0; i < 3; i++) {
        array[i] = range[i];
      }
      setRangeArray(array);
    } else {
      let temp = 0;
      let array = [];
      let y = rangeArray.at(-1).id - 1 - 6;
      for (
        let i = rangeArray.at(-1).id - 1;
        i > rangeArray.at(-1).id - 4;
        i--
      ) {
        array[temp] = range[y];
        temp = temp + 1;
        y = y + 1;
        // console.log("range[i]", range[i]);
      }
      setRangeArray(array);
    }
  };

  // console.log(props?.pickup?.date?.date, 'pickup date')

  const handleClick = async (item, time) => {
    if (props?.type === "pickup") {
      if (props?.dTime) {

        const uid = props?.authUser?.user_uid;
        const client = props?.authUser?.user_client;
        const accessToken = props?.authUser?.user_access_token;
        try {
          let response = await axios({
            url: `${Config.apiURL}/customer/validate_delivery_date`,
            method: "POST",
            headers: {
              uid: `${uid}`,
              client: `${client}`,
              "access-token": `${accessToken}`,
            },
            data: {
              pickup_date: props?.editDelivery ? props?.pickUpDateDelivery : props?.pickup?.date?.date,
              delivery_date: props?.dDate?.date?.date,
              pickup_slot: props?.editDelivery ? props?.pickUpSlotDelivery : item,
              delivery_slot: props?.dTime
            }
          })
          if (response?.data?.message == 'Valid') {
            setError(false)
            props.dispatch(setPTime(time));
            setId(item);
          }
        } catch (error) {
          setError(true)
          if (error?.response?.data?.message === "Delivery date must be after 48 hours of pickup date.") {
            setErrorMessage("Pickup date must be atleast 48 hours before delivery ")
          }
          else if (error?.response?.data?.message === "pickup or delivery slot not selected")
            setErrorMessage("Delivery slot not selected ")
        }
      }
      else {
        props.dispatch(setPTime(time));
        setId(item);
      }
    }
    else {
      if (props.homee === false) {
        setError(false)
        props.dispatch(setDTime(time));
        setId(item);
      } else {
        const uid = props?.authUser?.user_uid;
        const client = props?.authUser?.user_client;
        const accessToken = props?.authUser?.user_access_token;
        try {
          let response = await axios({
            url: `${Config.apiURL}/customer/validate_delivery_date`,
            method: "POST",
            headers: {
              uid: `${uid}`,
              client: `${client}`,
              "access-token": `${accessToken}`,
            },
            data: {
              pickup_date: props?.editDelivery ? props?.pickUpDateDelivery : props?.pickup?.date?.date,
              delivery_date: props?.selectedDate?.date,
              pickup_slot: props?.editDelivery ? props?.pickUpSlotDelivery : props?.pTime,
              delivery_slot: item
            }
          })
          if (response?.data?.message == 'Valid') {
            setError(false)
            props.dispatch(setDTime(time));
            setId(item);
          }
        } catch (error) {
          setError(true)
          if (error?.response?.data?.message === "Delivery date must be after 48 hours of pickup date.") {
            setErrorMessage(error.response.data.message)
          }
          else if (error?.response?.data?.message === "pickup or delivery slot not selected")
            setErrorMessage("Pickup slot not selected ")
        }
      }
    }
  };

  return (
    <>
      {error && <p style={{ color: " red " }}>{errorMessage}</p>}
      {props?.type === "pickup" ? (
        <ul className="p-0 m-0 pt-4 scheldue_width d-flex flex-row justify-content-center">
          {props?.currentSlot?.map((item, index) => {
            return props?.isCurrentDateActive &&
              props?.currentDate === todayDate &&
              todayHour >= item?.time.split("-")[0] ? null : (
              <li
                style={{ cursor: "pointer" }}
                onClick={() => {
                  handleClick(item?.time, item?.time);
                }}
                className={
                  item?.time === props.pTime
                    ? "clender_active"
                    : ""
                }
              >
                {item?.time}
              </li>
            );
          })}
        </ul>
      ) : (
        <ul className="p-0 m-0 pt-4 scheldue_width d-flex flex-row justify-content-center">
          {props?.currentSlot?.map((item, index) => {
            return (
              <li
                style={{ cursor: "pointer" }}
                // key={index}
                onClick={() => {
                  handleClick(item?.time, item?.time);
                }}
                className={
                  item?.time === props.dTime
                    ? "clender_active"
                    : ""
                }
              >
                {item?.time}
              </li>
            );
          })}
        </ul>
      )}
      {/*<div className="calender-div">*/}
      {/*  <button className="btn-calender" onClick={prev}>*/}
      {/*    {`<`}{" "}*/}
      {/*  </button>*/}
      {/*  <button className="btn-calender" onClick={handleNext}>*/}
      {/*    {" "}*/}
      {/*    {`>`}{" "}*/}
      {/*  </button>*/}
      {/*</div>*/}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    editDelivery: state.auth.edit,
    pickUpDateDelivery: state.auth.pickUpDateDelivery,
    pickUpSlotDelivery: state.auth.pickUpSlotDelivery,
    pTime: state.auth.pickupTime,
    dTime: state.auth.deliveryTime,
    dDate: state.cart.time,
    sAddress: state?.auth?.selectedAddress,
    homee: state?.cart?.home,
    authUser: state?.auth?.user,
    pickup: state?.cart?.pickup
  };
};

export default connect(mapStateToProps)(SlotRange);
