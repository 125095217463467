import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react'

const OrderDeletion = ({ show, mainText, subText, handleDeleteOrder, cancelDeletion, t }) => {
  if (!show) return null;

  return (
    <div className='view-order-modal'>
      <div className='view-order-modal-content'>
        <div className="d-flex flex-row align-items-center justify-content-end mb-0 mx-2">
          <FontAwesomeIcon
            onClick={cancelDeletion}
            icon={faTimesCircle}
            className="modal-close white fs-2 cursor_pointer"
            style={{ border: '6px solid #3D4586', borderRadius: 50, marginRight: -20, marginBottom: -25, zIndex: 1000, backgroundColor: '#3D4586' }}
          />
        </div>
        <div className='order_deletion_tracking theme_color text-center p-5 overflow-hidden'>
          <h3 className='fw-bold mb-5'>{mainText}</h3>
          <p className='mb-5'>{subText}</p>
          <div className='d-flex justify-content-around align-items-center'>
            <button
              onClick={cancelDeletion}
              className="edit_order_deletion_btn_2 px-2 py-1"
              aria-label="Cancel delete"
            >
              {t("No")}
            </button>
            <button
              onClick={handleDeleteOrder}
              className="edit_order_deletion_btn border-0 px-2 py-1 mx-2"
              aria-label="Confirm delete"
            >
              {t("Yes")}
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default OrderDeletion