import React from "react";
import "../../Header/user.css";
const ProgressStatus = (props) => {
  const { step, current } = props
  return (
    <>
      <div className="d-md-flex d-none w-100 mx-auto justify-content-center align-items-center flex-sm-row flex-column my-5">
        <div className="progress_circle progress_circle_active" />
        <div
          className={
            step >= 1
              ? "progress_line progress_line_active"
              : "progress_line"
          }
        />
        <div
          className={
            step >= 2
              ? "progress_line progress_line_active"
              : "progress_line"
          }
        />
        {step === 2 ? (
          <div className="main_outer_shadow">
            <div className="outer_shadow">
              <div className={current?.pick_up_issue ? "progress_circle progress_circle_active_issue" : "progress_circle progress_circle_active"}>
                <div className="white_circle" />
              </div>
            </div>
          </div>
        ) : (
          <div
            className={
              step >= 2
                ? "progress_circle progress_circle_active"
                : "progress_circle "
            }
          />
        )}
        <div
          className={step >= 2 && !current?.pick_up_issue
            ? "progress_line progress_line_active"
            : "progress_line"
          }
        />
        <div
          className={
            step >= 3
              ? "progress_line progress_line_active"
              : "progress_line"
          }
        />
        {step === 3 ? (
          <div className="main_outer_shadow">
            <div className="outer_shadow">
              <div className={current?.payment_status == 'failed' ? "progress_circle progress_circle_active_issue" : "progress_circle progress_circle_active"}>
                <div className="white_circle" />
              </div>
            </div>
          </div>
        ) : (
          <div
            className={
              step >= 3
                ? "progress_circle progress_circle_active "
                : "progress_circle "
            }
          />
        )}
        <div
          className={
            step >= 3 && current?.payment_status !== 'failed'
              ? "progress_line progress_line_active"
              : "progress_line"
          }
        />
        <div
          className={
            step >= 4
              ? "progress_line progress_line_active"
              : "progress_line"
          }
        />
        {step === 4 ? (
          <div className="main_outer_shadow">
            <div className="outer_shadow">
              <div className="progress_circle progress_circle_active">
                <div className="white_circle" />
              </div>
            </div>
          </div>
        ) : (
          <div
            className={
              step >= 4
                ? "progress_circle progress_circle_active"
                : "progress_circle "
            }
          />
        )}
        <div
          className={
            step >= 4
              ? "progress_line progress_line_active"
              : "progress_line"
          }
        />
        <div
          className={
            step >= 5
              ? "progress_line progress_line_active"
              : "progress_line"
          }
        />
        {step === 5 ? (
          <div className="main_outer_shadow">
            <div className="outer_shadow">
              <div className={current?.delivery_issue ? "progress_circle progress_circle_active_issue" : "progress_circle progress_circle_active"}>
                <div className="white_circle" />
              </div>
            </div>
          </div>
        ) : (
          <div
            className={
              step >= 5 && !current?.delivery_issue
                ? "progress_circle progress_circle_active"
                : "progress_circle "
            }
          />
        )}
      </div>
      {/* <div className="display_none_mobile">
        <div className="d-flex w-75 mx-auto justify-content-center align-items-center flex-sm-row flex-column mt-5 mb-4">
          <div className="progress_circle progress_circle_active current_step" />

          <div
            className={
              step >= 2
                ? "progress_line progress_line_active w-25"
                : "progress_line  w-25"
            }
          />
          {props?.step === 2 ? (
            <div className="main_outer_shadow">
              <div className="outer_shadow">
                <div className="progress_circle progress_circle_active current_step">
                  <div className="white_circle" />
                </div>
              </div>
            </div>
          ) : (
            <div
              className={
                step >= 2
                  ? "progress_circle progress_circle_active current_step"
                  : "progress_circle "
              }
            />
          )}
          <div
            className={
              step >= 3
                ? "progress_line progress_line_active w-25"
                : "progress_line  w-25"
            }
          />
          {props?.step === 3 ? (
            <div className="main_outer_shadow">
              <div className="outer_shadow">
                <div className="progress_circle progress_circle_active current_step">
                  <div className="white_circle" />
                </div>
              </div>
            </div>
          ) : (
            <div
              className={
                step >= 3
                  ? "progress_circle progress_circle_active current_step "
                  : "progress_circle "
              }
            />
          )}
          <div
            className={
              step >= 4
                ? "progress_line progress_line_active w-25"
                : "progress_line  w-25"
            }
          />
          {props?.step === 4 ? (
            <div className="main_outer_shadow">
              <div className="outer_shadow">
                <div className="progress_circle progress_circle_active current_step">
                  <div className="white_circle" />
                </div>
              </div>
            </div>
          ) : (
            <div
              className={
                step >= 4
                  ? "progress_circle progress_circle_active current_step"
                  : "progress_circle "
              }
            />
          )}
          <div
            className={
              step >= 5
                ? "progress_line progress_line_active w-25"
                : "progress_line  w-25"
            }
          />
          {props?.step === 5 ? (
            <div className="main_outer_shadow">
              <div className="outer_shadow">
                <div className="progress_circle progress_circle_active current_step">
                  <div className="white_circle" />
                </div>
              </div>
            </div>
          ) : (
            <div
              className={
                step >= 5
                  ? "progress_circle progress_circle_active current_step"
                  : "progress_circle "
              }
            />
          )}
        </div>
      </div> */}
    </>
  );
};

export default ProgressStatus;
