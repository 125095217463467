import * as actions from "../Actions/authActions";
import isEmpty from "lodash/isEmpty";

export const intialState = {
  isAuthenticated: false,
  user: {},
  error: false,
  homeAddress: [],
  officeAddress: [],
  profileUser: {},
  defaultAddress: {},
  selectedAddress: null,
  wallet: null,
  currentId: null,
  paymentId: [],
  pickupTime: null,
  deliveryTime: null,
  paymentMethod: null,
  paymentCard: {},
  edit: false,
  editOrder: false,
  pickupIssueFix: false,
  pickUpDateDelivery: null,
  pickUpSlotDelivery: null,
  desc: null,
  rating: "",
  current: {},
  index: 0,
  render: 0,
  isActive: null,
  loading: false,
};

export default function authReducer(state = intialState, action) {
  switch (action.type) {
    case actions.SET_CURRENT_USER:
      return {
        ...state,
        isAuthenticated: !isEmpty(action.user),
        user: action.user,
        error: false,
      };
    case actions.SET_CURRENT_LANG:
      return {
        ...state,
        user: {
          ...state.user,
          user_locale: action.user.user_locale,
        },
      };
    case actions.SET_ERROR:
      return {
        ...state,
        error: action.error,
        isAuthenticated: false,
        user: {},
      };
    case actions.SET_WALLET:
      return {
        ...state,
        wallet: action.wallet,
      };
    case actions.SET_CURRENT_ID:
      return {
        ...state,
        currentId: action.id,
      };
    case actions.SET_CURRENT:
      // alert(JSON.stringify(action.current))
      return {
        ...state,
        current: action.current,
      };
    case actions.SET_INDEX:
      return {
        ...state,
        index: action.index,
      };
    case actions.SET_EDIT:
      return {
        ...state,
        edit: action.val,
      };
    case actions.SET_PICKUP_DATE_DELIVERY:
      return {
        ...state,
        pickUpDateDelivery: action.val,
      };
    case actions.SET_PICKUP_SLOT_DELIVERY:
      return {
        ...state,
        pickUpSlotDelivery: action.val,
      };
    case actions.SET_EDIT_ORDER:
      return {
        ...state,
        editOrder: action.val,
      };
    case actions.SET_PICK_UP_ISSUE_FIX:
      return {
        ...state,
        pickupIssueFix: action.val,
      };
    case actions.SET_CLEAR_STATE:
      return {
        ...state,
        selectedAddress: null,
        wallet: null,
        currentId: null,
        pickupTime: null,
        deliveryTime: null,
        paymentMethod: null,
        paymentCard: {},
        edit: false,
        editOrder: false,
        pickupIssueFix: false,
        pickUpDateDelivery: null,
        pickUpSlotDelivery: null,
        desc: null,
        rating: "",
        current: {},
        index: 0,
        render: 0,
      };
    case actions.SET_PAYMENT_ID:
      return {
        ...state,
        paymentId: action.id,
      };
    case actions.SET_DESCRIPTION:
      return {
        ...state,
        desc: action.desc,
      };
    case actions.SET_RENDER:
      return {
        ...state,
        render: action.val,
      };
    case actions.SET_RATING:
      return {
        ...state,
        rating: action.rating,
      };
    case actions.SET_SELECTED_CARD:
      return {
        ...state,
        paymentCard: action.card,
      };
    case actions.SET_PTIME:
      return {
        ...state,
        pickupTime: action.time,
      };
    case actions.SET_DTIME:
      return {
        ...state,
        deliveryTime: action.time,
      };
    case actions.SET_DELIVERY_ADDRESS:
      // if (state.homeAddress.length <= 0) {
      //   return {
      //     ...state,
      //     homeAddress: state.homeAddress.concat(action.address),
      //   };
      // }
      return {
        ...state,
        homeAddress: action.address,
      };
    case actions.SET_OFFICE_ADDRESS:
      // if (state.homeAddress.length <= 0) {
      //   return {
      //     ...state,
      //     homeAddress: state.homeAddress.concat(action.address),
      //   };
      // }
      return {
        ...state,
        officeAddress: action.address,
      };
    case actions.SET_PROFILE:
      // console.log(action.profile)
      return {
        ...state,
        profileUser: action.profile,
      };
    case actions.SET_DEFAULT:
      return {
        ...state,
        defaultAddress: action.is_default,
      };
    case actions.SET_PAYMENT_METHOD:
      return {
        ...state,
        paymentMethod: action.method,
      };
    case actions.IS_ACTIVE:
      return {
        ...state,
        isActive: action.payload,
      };
    case actions.IS_LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    case actions.SET_CLEAR:
      return {
        isAuthenticated: false,
        user: {},
        error: false,
        homeAddress: [],
        officeAddress: [],
        profileUser: {},
        defaultAddress: {},
        selectedAddress: null,
        wallet: null,
        currentId: null,
        paymentId: [],
        pickupTime: null,
        deliveryTime: null,
        paymentMethod: null,
        paymentCard: {},
        edit: false,
        editOrder: false,
        pickupIssueFix: false,
        pickUpDateDelivery: null,
        pickUpSlotDelivery: null,
        desc: null,
        rating: "",
        current: {},
        index: 0,
        render: 0,
        loading: false
      };
    case actions.SET_SELECTED_ADDRESS:
      if (action.atype === "home") {
        let array = state?.homeAddress?.filter(
          (item) => item?.id === +action?.address
        );
        return {
          ...state,
          pickupTime: null,
          deliveryTime: null,
          selectedAddress: array,
        };
      } else {
        let array = state?.officeAddress?.filter(
          (item) => item?.id === +action?.address
        );
        return {
          ...state,
          pickupTime: null,
          deliveryTime: null,
          selectedAddress: array,
        };
      }

    default:
      return state;
  }
}
