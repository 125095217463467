import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import {
  setCurrentId,
  setSelectedAddress,
  setPaymentMethod,
  setPaymentCard,
  setCurrent,
  setEditOrder,
  setEdit,
} from "../../../../store/Actions/authActions";
import {
  setPastCart,
  selectAddress,
  setTimePickup,
  cartEdit,
  cartApi,
  fetchCartItem,
} from "../../../../store/Actions/cartActions";
import { useTranslation } from "react-i18next";

const EditOrderModal = ({ ...props }) => {
  const { t } = useTranslation();
  const [productId, setProductId] = useState(null);
  const [total, setTotal] = useState(null);
  const [card, setCard] = useState(null);
  const [data, setData] = useState();

  const handleChange = (event, id) => {
    setTotal(event.target.value);
    setProductId(id);
  };

  useEffect(() => {
    setData(props?.current);
  }, [props?.current]);
  useEffect(() => {
    let pin = props?.pId?.filter(
      (item) => item.id === props?.current?.payment_method_id
    );
    let temp = `**** **** **** ${pin[0]?.last4}`;
    setCard(temp);
  }, []);

  const handleEdit = async () => {
    let array = props?.current?.products?.map((item) => {
      const result = props?.items?.find(
        (product) => product.id === item.product_id && product?.is_active
      );
      if (result?.is_active) {
        return {
          ...result,
          quantity: item?.quantity,
          price: +result?.price * item?.quantity,
          table_id: item?.id,
          order_id: item?.order_id,
        };
      }
    });
  
    let final = array.filter((item) => item !== undefined);
  
    let type = props?.current?.collection_point_id ? "office" : "home";
    if (type === "home") {
      props?.dispatch(setSelectedAddress(`${props?.current?.delivery_address_id}`, type));
      props?.dispatch(selectAddress(type));
    } else {
      props?.dispatch(setSelectedAddress(`${props?.current?.collection_point_id}`, "office"));
      props?.dispatch(selectAddress(type));
    }
  
    props?.dispatch(setCurrentId(props?.current?.id));
    props?.dispatch(setCurrent(props?.current?.products));
    props?.dispatch(setEditOrder(true));
    props?.dispatch(setEdit(false));
    props?.dispatch(
      setPaymentCard(
        props?.current?.payment_method_id ? props?.current?.payment_method_id : null
      )
    );
    props?.dispatch(
      setPaymentMethod(
        props?.current?.payment_method_id ? "Debit Card" : "Wallet"
      )
    );
  
    await synchronizeCart();
    props?.history?.push("/checkout");
  };
  
  const synchronizeCart = async () => {
    try {
      await props.dispatch(fetchCartItem());
      await Promise.all(
        props?.cartItems?.map((p) =>
          props?.dispatch(cartApi(p, "destroyAllProducts"))
        )
      );
      await props?.dispatch(cartEdit(props.current, props?.history));
      await props.dispatch(fetchCartItem());
    } catch (error) {
      console.error("Error synchronizing cart:", error);
    }
  };
  

  return (
    <div id="">
      <div
        className="modal fade"
        id="editOrder"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex={-1}
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        {/* {alert(JSON.stringify(props.current))} */}
        <div className="modal_main h-100 w-100 position-absolute"></div>
        <div className="modal-dialog flex-column modal-dialog-centered vh-90">
          <div className="modal-content bg-transparent mb-5 border-0 w_75 screen_center">
            <div className="d-flex flex-row align-items-center justify-content-between mb-2 mx-2">
              <h3 className="white">{t("Edit order")}</h3>
              <FontAwesomeIcon
                icon={faTimesCircle}
                className="white fs-2 cursor_pointer"
                data-bs-dismiss="modal"
                aria-hidden="true"
              />
            </div>
            <div className="modal-body rounded_25px bg-light p-4">
              <div className="w-100 d-flex">
                <div>
                  {!props?.current?.products?.length ? (
                    <p>Error</p>
                  ) : (
                    <div>
                      <div className="d-flex flex-column mb-4">
                        <h4 className="theme_color fs-3 fw-bold">
                          {t("Edit product quantity")}
                        </h4>
                        <div class="heading-line line_dark w-25 mb-3"></div>
                      </div>

                      <div className="d-flex flex-row justify-content-between mb-2">
                        <h5 className="theme_color fs-5 fw-bold mb-2">
                          {t("Items")}
                        </h5>
                        <h5 className="theme_color fs-5 fw-bold mb-2 text-end">
                          {t("quantity")}
                        </h5>
                      </div>
                      {props.current?.products?.map((item, index) => {
                        const result = props?.items?.find((product) => {
                          if (product.id === item.product_id) {
                            return product;
                          }
                        });
                        return (
                          <>
                            <div className="d-flex flex-row justify-content-between">
                              <p className="fs-6 m-0">{t(result?.name)} </p>
                              <p className="fw-bold fs-6 text-end">
                                {item?.quantity}
                              </p>
                            </div>
                          </>
                        );
                      })}

                      {/*<div className="d-flex flex-row justify-content-between mb-2">*/}
                      {/*  <p className="theme_color fs-5 fw-bold mb-2">*/}
                      {/*    {t("Promo code")}*/}
                      {/*  </p>*/}
                      {/*  <p className="fs-5 mb-2 text-end">*/}
                      {/*    {props?.current?.promo_code*/}
                      {/*      ? `${props?.current?.promo_code}`*/}
                      {/*      : "None"}*/}
                      {/*  </p>*/}
                      {/*</div>*/}
                      {/*{props?.current?.payment_method_id ? (*/}
                      {/*  <div className="d-flex flex-row justify-content-between mb-2">*/}
                      {/*    <p className="theme_color fs-5 fw-bold mb-2">*/}
                      {/*      {t("card number")}*/}
                      {/*    </p>*/}
                      {/*    <p className="fs-5 mb-2 text-end">{card}</p>*/}
                      {/*  </div>*/}
                      {/*) : (*/}
                      {/*  <div className="d-flex flex-row justify-content-between mb-2">*/}
                      {/*    <p className="theme_color fs-5 fw-bold mb-2">*/}
                      {/*      {t("payment method")}*/}
                      {/*    </p>*/}
                      {/*    <p className="fs-5 mb-2 text-end">Wosh Wallet</p>*/}
                      {/*  </div>*/}
                      {/*)}*/}
                      <div className="d-flex flex-row justify-content-between mb-2">
                        <p className="theme_color fs-5 fw-bold mb-2">
                          {t("pick-up")}:
                        </p>
                        <p className="theme_color fs-5 fw-bold mb-2 text-end">
                          {t("delivery")}:
                        </p>
                      </div>
                      <div className="d-flex flex-row justify-content-between">
                        <p className="fs-6 m-0">
                          {props?.current?.pickup_date}
                        </p>
                        <p className="fs-6 text-end">
                          {props?.current?.delivery_date}
                        </p>
                      </div>
                      <div className="d-flex flex-row justify-content-between">
                        <p className="fs-6 m-0">
                          {props?.current?.pickup_slot}
                        </p>
                        <p className="fs-6 text-end">
                          {props?.current?.delivery_slot}
                        </p>
                      </div>

                      <button
                        onClick={() => {
                          handleEdit();
                        }}
                        data-bs-dismiss="modal"
                        aria-hidden="true"
                        className="btn btn-custom-onboard mt-4 mb-2 ms-auto me-auto d-flex justify-content-center"
                      >
                        {t("continue")}
                      </button>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    editOrder: state.auth.editOrder,
    items: state?.cart?.mockItems,
    homeAddress: state?.auth?.homeAddress,
    officeAddress: state?.auth?.officeAddress,
    pId: state?.auth?.paymentId,
    cartItems: state?.cart?.cartItems,
  };
};
export default connect(mapStateToProps)(EditOrderModal);
