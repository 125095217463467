import React from "react"

const OrderErrorDetails = (props) => {
  const {
    mainHeading,
    subHeading,
    detail,
    btnStyle,
    secondaryBtnStyle,
    primaryOnclick,
    btnText,
    secondaryBtnType,
    secondaryBtnOnclick,
    secondaryBtnText,
    linkOnclick,
    viewOrder,
  } = props
  return (
    <>
      <div className="order_tracking_message mx-auto">
        <div className="d-flex flex-column align-items-center theme_color">
          <h5 className="fw-bold">{mainHeading}</h5>
          <h6 className="text-center fw-bold my-4 px-5">{subHeading}</h6>
          <p className="text-center">{detail}</p>
          <button
            className="d-flex justify-content-center small align-items-center border-0 rounded-pill py-2 mt-4"
            style={btnStyle}
            onClick={() => primaryOnclick()}
          >
            {btnText}
          </button>

          {secondaryBtnType === "btn" && (
            <button
              className="d-flex justify-content-center small align-items-center secondaryBtnStyle rounded-pill py-2 mt-4"
              // style={secondaryBtnStyle}
              onClick={() => secondaryBtnOnclick()}
            >
              {secondaryBtnText}
            </button>
          )}

          {secondaryBtnType === "link" && (
            <>
              <div className="d-flex justify-content-between align-items-center w-50 my-2">
                <hr
                  className="w-100"
                  style={{
                    border: "1px solid #E7E9FF",
                    opacity: 1,
                  }}
                />
                <p className="m-0 mx-3">OR</p>
                <hr
                  className="w-100"
                  style={{
                    border: "1px solid #E7E9FF",
                    opacity: 1,
                  }}
                />
              </div>
              <p className="delete_style" onClick={() => linkOnclick()}>
                {secondaryBtnText}
              </p>
            </>
          )}
        </div>
      </div>
      {viewOrder && (
        <div className="d-flex justify-content-center mt-5">
          <p className="theme_color" style={{cursor: 'pointer'}}>{viewOrder}</p>
        </div>
      )}
    </>
  )
}

export default OrderErrorDetails
