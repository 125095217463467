import React, { useCallback, useState } from "react";
import Header from "../Header";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import Axios from "axios";
import SelectCard from "../Modals/MasterCard/SelectCard";
import "./../Header/user.css";
import { Link } from "react-router-dom";
import { useEffect } from "react";
import WoshWallet from "../Modals/WoshWallet";
import { connect } from "react-redux";
import Address from "../Modals/Address";
import Config from "../../Config";
import {
  cartApi,
  CartIncDec,
  incDec,
  selectAddress,
  setQuantity,
} from "../../../store/Actions/cartActions";
import {
  setAddress,
  setDescription,
  setPaymentMethod,
  setSelectedAddress,
  set_OfficeAddress,
  setPaymentCard,
  setEditOrder,
} from "../../../store/Actions/authActions";
import PromoCode from "../Modals/PromoCode";
import ConfirmOrder from "../Modals/ConfirmOrder";
import SelectButton from "./SelectButton";
import PickupDelivery from "./PickupDelivery";
import { useTranslation } from "react-i18next";
import ChooseMethod from "../Modals/ChooseMethod";
import * as ReactBootStrap from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlusCircle, faMinusCircle } from "@fortawesome/free-solid-svg-icons";
import cookies from "js-cookie";
import { useLocation } from "react-router-dom/cjs/react-router-dom";
import EditDelivery from "../Modals/EditDelivery";
import OrderDeletion from "../Modals/OrderDeletion";

const Checkout = (props, msg) => {
  const { t } = useTranslation();
  const [total, setTotal] = useState(0);
  const [pDiscount, setPDiscount] = useState(null);
  const [percentDisc, setPercentDisc] = useState(null);
  const [wallet, setWallet] = useState(null);
  const [error, setError] = useState("");
  const [profile, setProfile] = useState(null);
  const [load, setLoad] = useState(false);
  const [reload, setReload] = useState(false);
  const [selectedWallet, setSelectedWallet] = useState(false);
  const [loading, setLoading] = useState(true);
  const [confirmDelete, setConfirmDelete] = useState(false)
  const [orderToDelete, setOrderToDelete] = useState(null)
  const [modalOpen, setModalOpen] = useState(false)
  const [err, setErr] = useState(false)
  // const location = useLocation()

  // const previousPage = location.state?.from

  // console.log(previousPage, 'previous page')

  // useEffect(() => {
  //   if (previousPage) {
  //     console.log(`Navigated from: ${previousPage}`);
  //     // Perform any actions you need based on the previous page
  //   }
  // }, [previousPage]);

  const handleDestroy = () => {
    props?.dispatch(setEditOrder(false))
    props?.cartItems?.forEach((p) => {
      props?.dispatch(cartApi(p, "destroyAllProducts"))
    })
    props?.history.push('/home')
  }
  // const handleDeleteOrder = async (id) => {
  //   if (props?.editOrder && orderToDelete) {
  //     const uid = props?.authUser?.user_uid;
  //     const client = props?.authUser?.user_client;
  //     const accessToken = props?.authUser?.user_access_token;
  //     setConfirmDelete(true);
  //     try {
  //       const response = await Axios({
  //         url: `${Config.apiURL}/customer/orders/${id}`,
  //         method: "DELETE",
  //         headers: {
  //           uid: `${uid}`,
  //           client: `${client}`,
  //           "access-token": `${accessToken}`,
  //         },
  //       });
  //       if (response.status === 200) {
  //         console.log('Order deleted successfully.');
  //         props?.dispatch(setEditOrder(false))
  //         props?.cartItems?.forEach((p) => {
  //           props?.dispatch(cartApi(p, "destroyAllProducts"))
  //         })
  //         props?.history.push('/home')
  //       } else {
  //         console.error('Failed to delete the order.');
  //         setErr({ error: true });
  //       }
  //     } catch (error) {
  //       console.error("Error deleting order:", error);
  //       setErr({ error: true });
  //     }
  //   }
  // }

  const handleDeleteOrder = async () => {
    if (orderToDelete) {
      try {
        const uid = props?.authUser?.user_uid;
        const client = props?.authUser?.user_client;
        const accessToken = props?.authUser?.user_access_token;
  
        const response = await Axios({
          url: `${Config.apiURL}/customer/orders/${orderToDelete}`,
          method: "DELETE",
          headers: {
            uid: `${uid}`,
            client: `${client}`,
            "access-token": `${accessToken}`,
          },
        });
  
        if (response.status === 200) {
          console.log('Order deleted successfully.');
          props?.dispatch(setEditOrder(false));
          props?.cartItems?.forEach((p) => {
            props?.dispatch(cartApi(p, "destroyAllProducts"));
          });
          props?.history.push('/home');
        } else {
          console.error('Failed to delete the order.');
          setErr({ error: true });
        }
      } catch (error) {
        console.error("Error deleting order:", error);
        setErr({ error: true });
      } finally {
        setModalOpen(false);
        setOrderToDelete(null);
      }
    }
  };

  const openConfirmation = (id) => {
    setOrderToDelete(id);
    setModalOpen(true);
  };

  const cancelDeletion = () => {
    setModalOpen(false);
    setOrderToDelete(null);
  };

  useEffect(async () => {
    await getProfiles();
    setLoad(true);
    if (load) {
      setLoad(false);
      // props?.dispatch(set_OfficeAddress());
      // props?.dispatch(setAddress());
      // setTimeout(() => {
      setAdd();
      // }, 3000)
    }
  }, [reload]);

  const setAdd = () => {
    let h = props?.hAddress?.filter((item) => {
      return item?.is_default === true;
    });
    let o = props?.oAddress?.filter((item) => {
      return item?.is_default === true;
    });

    if (h?.length > 0) {
      props.dispatch(setSelectedAddress(h[0]?.id, "home"));
      props?.dispatch(selectAddress("home"));
    }

    if (h?.length === 0 && o?.length > 0) {
      props?.dispatch(setSelectedAddress(o[0]?.id, "office"));
      props?.dispatch(selectAddress("office"));
    }
  };

  const handleDispatch = (item, sign) => {
    props.dispatch(cartApi(item, sign));
    // props.dispatch(incDec(item, sign));
  };
  const getAmount = async () => {
    const uid = props?.authUser?.user_uid;
    const client = props?.authUser?.user_client;
    const accessToken = props?.authUser?.user_access_token;
    try {
      const response = await Axios({
        url: `${Config.apiURL}/customer/wallet`,
        method: "get",
        headers: {
          uid: `${uid}`,
          client: `${client}`,
          "access-token": `${accessToken}`,
        },
      });
      const amount = await response?.data?.wallet?.amount;
      setReload(true);
      setWallet(amount);
      // setReload(true)
      // debugger
      // if (amount >= props?.totalAmount) {
      //   props.dispatch(setPaymentMethod("Wallet"))
      // }
      //  else if (props?.paymentId?.length >= 1 && amount < props?.totalAmount) {
      //   let id = props?.cards[0]?.id
      //   props.dispatch(setPaymentCard(id));
      //   props.dispatch(setPaymentMethod("Debit Card"))
      // }
      // else {
      //   props.dispatch(setPaymentMethod("null"))
      // }
    } catch (err) {
      // console.log("wallet error, ", err.response);
    }
  };

  const getProfiles = async () => {
    const uid = props?.authUser?.user_uid;
    const client = props?.authUser?.user_client;
    const accessToken = props?.authUser?.user_access_token;
    try {
      const response = await Axios({
        url: `${Config.apiURL}/customer/profiles`,
        method: "get",
        headers: {
          uid: `${uid}`,
          client: `${client}`,
          "access-token": `${accessToken}`,
        },
      });
      setProfile(response.data.profile);
    } catch (err) { }
  };

  useEffect(async () => {
    await getAmount();
    // if (wallet > props?.totalAmount) {
    //   props.dispatch(setPaymentMethod("Wallet"))
    // }else if(props?.paymentMethod && wallet < props?.totalAmount) {
    //   props.dispatch(setPaymentMethod("Debit Card"))
    // } else {
    //  return null
    // }
    // setAdd();
    if (props?.pCode?.fixed_amount) {
      if (props?.totalAmount <= props?.pCode?.discount) {
        let disc = props?.totalAmount;
        let updatedTotal = props?.address
          ? props?.totalAmount - disc + 4.9
          : props?.totalAmount - disc;
        // console.log("temp", total);
        setPDiscount(disc);
        if (props.sAddress?.[0]?.service_fee) {
          setTotal(+props.sAddress?.[0]?.service_fee + +updatedTotal);
        } else {
          setTotal(updatedTotal.toFixed(2));
        }
        if (disc !== 0) {
          localStorage.setItem("tM", JSON.stringify(updatedTotal));
        }
      } else {
        let disc = props?.pCode?.discount;
        let updatedTotal = props?.address
          ? props?.totalAmount - disc + 4.9
          : props?.totalAmount - disc;
        // console.log("temp", total);
        setPDiscount(disc);
        if (props.sAddress?.[0]?.service_fee) {
          setTotal(+props.sAddress?.[0]?.service_fee + +updatedTotal);
        } else {
          setTotal(updatedTotal.toFixed(2));
        }
        if (disc !== 0) {
          localStorage.setItem("tM", JSON.stringify(updatedTotal));
        }
      }
    } else {
      let temp =
        props.pCode && props.pCode.discount != undefined
          ? props.pCode?.discount / 100
          : 0;
      let newTotal = props?.totalAmount * temp;
      let disc = props?.totalAmount - newTotal;
      let updatedTotal = props?.address
        ? props?.totalAmount - newTotal + 4.9
        : props?.totalAmount - newTotal;
      // console.log("temp", total);
      setPercentDisc(newTotal.toFixed(2));
      if (props.sAddress?.[0]?.service_fee) {
        setTotal(+props.sAddress?.[0]?.service_fee + +updatedTotal.toFixed(2));
      } else {
        setTotal(updatedTotal.toFixed(2));
      }
      if (newTotal !== 0) {
        localStorage.setItem("tM", JSON.stringify(updatedTotal));
      }
    }
    // debugger
    if (+wallet >= +total) {
      props.dispatch(setPaymentMethod("Wallet"));
    } else if (props?.paymentId?.length >= 1 && +wallet < +total) {
      let id = props?.cards[0]?.id;
      props.dispatch(setPaymentCard(id));
      props.dispatch(setPaymentMethod("Debit Card"));
    } else {
      props.dispatch(setPaymentMethod("null"));
    }
  }, [
    props?.pCode,
    props?.totalAmount,
    props?.paymentId,
    props?.sAddress,
    props?.renderCheck,
    total,
  ]);
  const item = () => {
    if (props?.cartItems?.length > 0) {
      return props?.cartItems?.map((item, index) => {
        return (
          <div className="row mb-5">
            <div className="col-4">
              <h6 className="mb-1 theme_color">
                {cookies.get("i18next") === "en"
                  ? item?.name
                  : cookies.get("i18next") === "fn"
                    ? item?.name_fr
                    : cookies.get("i18next") === "du"
                      ? item?.name_nl
                      : item?.name}
              </h6>
            </div>
            <div className="col-4  d-flex">
              <div className="d-flex w-100 flex-row justify-content-between align-items-center">
                {props?.editDelivery ? (
                  <span className="d-flex justify-content-center ms-5 mx-2 theme_color">
                    {item?.quantity}
                  </span>
                ) : (
                  <>
                    <button
                      style={{
                        backgroundColor: "transparent",
                        border: "none",
                        borderRadius: "0 0 0 0",
                        padding: 0,
                        margin: 0,
                      }}
                    >
                      {/* <i
                    className="fa fa-minus-circle theme_color"
                    aria-hidden="true"
                    onClick={() => handleDispatch(item, "minus")}
                    /> */}
                      <FontAwesomeIcon
                        icon={faMinusCircle}
                        className="theme_color"
                        aria-hidden="true"
                        onClick={() => handleDispatch(item, "minus")}
                      />
                    </button>
                    <span className="mx-2 theme_color">{item?.quantity}</span>
                    <button
                      style={{
                        backgroundColor: "transparent",
                        border: "none",
                        borderRadius: "0 0 0 0",
                        padding: 0,
                        margin: 0,
                      }}
                    >
                      {/* <i
                    className="fa fa-plus-circle theme_color"
                    aria-hidden="true"
                    onClick={() => handleDispatch(item, "plus")}
                    /> */}
                      <FontAwesomeIcon
                        icon={faPlusCircle}
                        className="theme_color"
                        aria-hidden="true"
                        onClick={() => handleDispatch(item, "plus")}
                      />
                    </button>
                  </>
                )}
              </div>
            </div>
            <div className="col-4">
              <p className="mb-0 text-end theme_color">
                {parseFloat(item?.price)?.toFixed(2)?.replace(".", ",")} €{" "}
              </p>
            </div>
          </div>
        );
      });
    } else {
      if (props.editDelivery === true || props.editOrder === true) {
      } else {
        props?.history?.push("/home");
      }
    }
  };

  const setLoadingFalse = useCallback(() => {
    setTimeout(() => {
      if (props.sAddress && props.sAddress.length > 0) {
        setLoading(false);
      }
    }, 1000);
  }, [props.sAddress]);

  useEffect(() => {
    setLoadingFalse();
  }, [setLoadingFalse]);

  return (
    <div className="h-100">
      <div className="top_right">
        <img src="/assets/imgs/bubble_3.png" alt="" />
      </div>
      <div className="bottom_right">
        <img src="/assets/imgs/bubble_2.png" className="img-fluid" alt="" />
      </div>
      <div className="middle_left">
        <img src="/assets/imgs/bubble.png" alt="" />
      </div>
      {props?.editOrder && (
        <div className="theme_green_bg py-2">
          <div className="row align-items-center px-5">
            <div className="col-md-4 d-flex justify-content-lg-start justify-content-center">
              {modalOpen && (
                <OrderDeletion
                  show={modalOpen}
                  mainText={t("Delete order")}
                  subText={t("Are you sure you would like to delete your order?")}
                  handleDeleteOrder={handleDeleteOrder}
                  cancelDeletion={cancelDeletion}
                  t={t}
                />
              )}
              <button
                onClick={() => openConfirmation(props?.currentId)}
                className="bg-transparent white border rounded border-white px-2 py-1"
                aria-label="Delete order"
              >
                {t("Delete order")}
              </button>
            </div>
            <div className="col-md-4">
              <p className="text-center white m-0">
                {modalOpen
                  ? 'Are you sure you want to delete your order?'
                  : 'You are currently editing an order'}
              </p>
            </div>
            <div className="col-md-4 d-flex justify-content-lg-end justify-content-center">
              <p className="white fs-2 m-0 cursor_pointer" onClick={() => {
                handleDestroy()
              }}>&times;</p>
            </div>
          </div>
        </div>
      )}
      {/* <Header isNav={true} /> */}
      <div className="w-100" />
      {!loading ? (
        // props?.sAddress && props?.sAddress.length > 0 ?
        <>
          <div className={props?.editOrder ? "container z_index_one scroll_tab_content_new_edit_order" : "container z_index_one scroll_tab_content_new"}>
            <div className="d-flex flex-column justify-content-center align-items-center mt-3 mb-5">
              <h3 className="theme_color fw_semibold">{t("Checkout")}</h3>
              <div className="heading-line w-50px" />
              {error != "" && (
                <p className="mt-3" style={{ color: " red " }}>
                  {error}
                </p>
              )}
            </div>
            <div className="row mb-5">
              <div className="col-lg-4 col-md-2 col-sm-2 col-xs-12" />
              <div className="col-lg-4 col-md-8 col-sm-8 col-xs-12">
                {!loading ? <PickupDelivery /> : null}
                <SelectButton />
                <div className="rounded theme_border p-3 mb-4">
                  <h4 className="text-decoration-underline theme_color mb-3">
                    {t("items")}
                  </h4>
                  <div className="row mb-2">
                    {/* <div className="col-4">
                  <h6 className="mb-1 theme_color">Laundry bag</h6>
                </div> */}
                  </div>
                  {item()}
                  {!props?.editDelivery && (
                    <Link
                      to="/home"
                      className="btn w-100 rounded-pill fill_btn theme_bg_btn theme_color"
                    >
                      {t("add item")}
                    </Link>
                  )}
                </div>
                {props?.paymentId?.length > 0 ? (
                  <div className="row mb-4">
                    <div className="col-md-4">
                      <button
                        onclick="modalblur()"
                        data-bs-toggle="modal"
                        data-bs-target="#wosh_wallet"
                        className={
                          props?.paymentMethod === "Wallet"
                            ? "w-100 min_heiight60px btn mb-2 payment_btn woshwallet d-flex flex-column justify-content-center align-items-center px-2 payment_border shadow-none"
                            : "w-100 min_heiight60px btn mb-2 payment_btn woshwallet d-flex flex-column justify-content-center align-items-center px-2  shadow-none"
                        }
                      >
                        <span className="fw_medium">Wosh Wallet</span>
                        <span className="small fw_regular">
                          {wallet
                            ? `${wallet?.replace(".", ",")} €`
                            : "0€ available"}
                        </span>
                      </button>
                    </div>
                    <div className="col-md-4">
                      <button
                        // style={{ border: "3px solid #19DBCF" }}
                        onclick="modalblur()"
                        data-bs-toggle="modal"
                        data-bs-target="#mastercard"
                        className={
                          props?.paymentMethod === "Debit Card"
                            ? "w-100 min_heiight60px btn mb-2 payment_btn mastercard theme_bg d-flex justify-content-center align-items-center px-2 payment_border shadow-none"
                            : "w-100 min_heiight60px btn mb-2 payment_btn mastercard theme_bg d-flex justify-content-center align-items-center px-2 shadow-none"
                        }
                      >
                        <img alt="" src="/assets/imgs/cards.png" width={45} />
                      </button>
                    </div>
                    <div className="col-md-4">
                      <button
                        className="w-100 min_heiight60px btn payment_btn addmethod px-2 theme_color fw_semibold"
                        data-bs-toggle="modal"
                        // data-bs-target="#stripeModal"
                        data-bs-target="#chooseMethod"
                      >
                        {t("+ Add Method")}
                      </button>
                    </div>
                  </div>
                ) : (
                  <div className="row mb-4">
                    <div className="col-md-6">
                      <button
                        onclick="modalblur()"
                        data-bs-toggle={props?.editDelivery ? "" : "modal"}
                        data-bs-target={
                          props?.editDelivery ? "" : "#wosh_wallet"
                        }
                        className={`${props?.editDelivery && "cursor"} 
                          ${props?.paymentMethod === "Wallet"
                            ? "w-100 min_heiight60px btn mb-2 payment_btn woshwallet d-flex flex-column justify-content-center align-items-center px-2 payment_border shadow-none"
                            : "w-100 min_heiight60px btn mb-2 payment_btn woshwallet d-flex flex-column justify-content-center align-items-center px-2  shadow-none"
                          }
                        `}
                      >
                        <span className="sr_font">WoshWallet</span>
                        <span className="payment_amount small">
                          {wallet
                            ? `${wallet?.replace(".", ",")} €`
                            : "0€ available"}
                        </span>
                      </button>
                    </div>
                    <div className="col-md-6">
                      <button
                        className={
                          props?.editDelivery
                            ? "cursor w-100 min_heiight60px btn payment_btn addmethod px-2"
                            : "w-100 min_heiight60px btn payment_btn addmethod px-2"
                        }
                        data-bs-toggle={props?.editDelivery ? "" : "modal"}
                        data-bs-target={
                          props?.editDelivery ? "" : "#chooseMethod"
                        }
                      >
                        + {t("Add method")}
                      </button>
                    </div>
                  </div>
                )}
                <div className="d-flex flex-row justify-content-between align-items-center mb-4">
                  <button
                    // onClick={handleValidate}
                    data-bs-toggle={props?.editDelivery ? "" : "modal"}
                    data-bs-target={props?.editDelivery ? "" : "#promocode"}
                    className={
                      props?.editDelivery
                        ? "cursor w-100 min_heiight60px btn payment_btn addmethod px-2"
                        : "w-100 min_heiight60px btn payment_btn addmethod px-2"
                    }
                  >
                    {t("Promo code")}
                  </button>
                </div>
                <textarea
                  disabled={props?.editDelivery}
                  onChange={(event) => {
                    props?.dispatch(setDescription(event.target.value));
                  }}
                  type="text"
                  name="message"
                  placeholder={t("Any comments or special instructions?")}
                  className="w-100 form-control form_input_style w_65per height_100px mb-4 theme_color"
                // defaultValue={""}
                />
                <ul className="p-0 m-0">
                  <li className="d-flex flex-row justify-content-between align-items-center">
                    <span className="theme_color">{t("payment method")}</span>
                    <span className="text-end theme_color">
                      {props?.paymentMethod === "Debit Card"
                        ? "Credit Card"
                        : props?.paymentMethod === "Wallet"
                          ? "Wallet"
                          : `${t("not selected")}`}
                    </span>
                  </li>
                  {props?.address ||
                    (props.sAddress?.[0]?.service_fee &&
                      props?.sAddress?.[0]?.service_fee !== "0.0") ? (
                    <li className="d-flex flex-row justify-content-between align-items-center">
                      <span className="theme_color">
                        {props.sAddress?.[0]?.type === "CollectionPoint" &&
                          props.sAddress?.[0]?.service_fee
                          ? t("service fee")
                          : t("delivery fee")}
                      </span>
                      <span className="text-end theme_color">
                        {props.sAddress?.[0]?.type === "CollectionPoint" &&
                          props.sAddress?.[0]?.service_fee
                          ? props.sAddress?.[0]?.service_fee + " €"
                          : "4,90 €"}{" "}
                      </span>
                    </li>
                  ) : null}
                  <li className="d-flex flex-row justify-content-between align-items-center">
                    <span className="theme_color">{t("subtotal")}</span>
                    <span className="text-end theme_color">
                      {props?.totalAmount?.toFixed(2).replace(".", ",")} €{" "}
                    </span>
                  </li>
                  <li className="d-flex flex-row justify-content-between align-items-center">
                    <span className="theme_color">{t("promotion")}</span>
                    <span className="text-end theme_color">
                      {props?.pCode && props?.pCode?.discount
                        ? props?.pCode?.fixed_amount
                          ? `${pDiscount?.toString().replace(".", ",")} €`
                          : `${percentDisc?.replace(".", ",")} €`
                        : "0"}
                      {/* %{" "} */}
                    </span>
                  </li>
                </ul>
                <div className="heading-line my-3 w-100" />
                <div className="d-flex flex-row justify-content-between align-items-center w-100 mb-4">
                  <h4 className="theme_color fw-bold fontsize_17px">
                    {t("total")}
                  </h4>
                  <h4 className="theme_color fw-bold fontsize_17px">
                    {total > 0
                      ? `€${parseFloat(total).toFixed(2)}`.replace(".", ",")
                      : props?.address === true
                        ? `€${parseFloat(total).toFixed(2)}`.replace(".", ",")
                        : `€${parseFloat(total).toFixed(2)}`.replace(".", ",")}
                  </h4>
                </div>
                {props?.editDelivery ? (
                  <button
                    data-bs-toggle="modal"
                    data-bs-target="#editdelivery"
                    className="btn fill_btn rounded_25px w-100"
                  >
                    {t("Continue")}
                  </button>
                ) : (
                  <button
                    data-bs-toggle="modal"
                    data-bs-target="#confirmOrder"
                    className="btn fill_btn rounded_25px w-100"
                  >
                    {t("Continue")}
                  </button>
                )}
              </div>
              <div className="col-lg-4 col-md-2 col-sm-2 col-xs-12" />
            </div>
          </div>
          <PromoCode />
          <WoshWallet amount={wallet} total={props?.totalAmount} />
          <SelectCard />
          <Address address={props?.deliveryAddress} />

          <EditDelivery
            errr={(e) => setError(e)}
            history={props?.history}
            setReload={(e) => {
              setTimeout(() => {
                setReload(!reload);
              }, 2000);
            }}
            totl={total}
          />
          <ConfirmOrder
            errr={(e) => setError(e)}
            history={props?.history}
            setReload={(e) => {
              setTimeout(() => {
                setReload(!reload);
              }, 2000);
            }}
            totl={total}
            profile={profile}
          />
          <ChooseMethod amount={wallet} />
        </>
      ) : (
        <ReactBootStrap.Spinner animation="grow" className="bounce" />
      )}
    </div>
  );
};
const mapStateToProps = (state) => ({
  editDelivery: state.auth.edit,
  editOrder: state.auth.editOrder,
  currentId: state?.auth?.currentId,
  pickUpDateDelivery: state.auth.pickUpDateDelivery,
  cartItems: state.cart.cartItems,
  totalAmount: state.cart.totalAmount,
  address: state.cart.home,
  deliveryAddress: state.auth.homeAddress,
  dTime: state?.cart?.time,
  pTime: state?.cart?.pickup,
  pCode: state?.cart?.promo,
  sAddress: state?.auth?.selectedAddress,
  pickupTime: state?.auth?.pickupTime,
  deliveryTime: state?.auth?.deliveryTime,
  paymentMethod: state?.auth?.paymentMethod,
  paymentId: state?.auth?.paymentId,
  renderCheck: state?.auth?.render,
  desc: state?.auth?.desc,
  authUser: state?.auth?.user,
  hAddress: state?.auth?.homeAddress,
  oAddress: state?.auth?.officeAddress,
  cards: state?.auth?.paymentId,
});

export default connect(mapStateToProps)(Checkout);
