import React from 'react';
import cookies from "js-cookie";
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';

const OrderDetailsModal = ({ show, toggle, onClose, order, t }) => {
  // if (!show) return null;
  if (!order) return null;

  const formattedSubTotal = parseFloat(order.subtotal || 0);
  const serviceFee = parseFloat(order.service_fee || 0);
  const promotionTotal = 0.0;
  const sumTotal = formattedSubTotal + serviceFee - promotionTotal;
  const finalTotal = sumTotal.toFixed(2);
  const taxIncluded = (0.21 * sumTotal).toFixed(2);

  return (
    <div className={`${show ? 'view-order-modal' : 'd-none'}`} show={show}>
      <div className="view-order-modal-content">
        {/* <div className="d-flex flex-row align-items-center justify-content-end mb-0 mx-2">
          <FontAwesomeIcon
            // onClick={onClose}
            onClick={toggle}
            icon={faTimesCircle}
            className="modal-close white fs-2 cursor_pointer"
            data-bs-dismiss="modal"
            aria-hidden="true"
            style={{ border: '6px solid #3D4586', borderRadius: 50, marginRight: -20, marginBottom: -25, zIndex: 1000, backgroundColor: '#3D4586' }}
          />
        </div> */}

        <div className="order_tracking_items mx-auto p-4 overflow-hidden">
          <ul className="m-0">
            <div className="row mb-4">
              <div className="col-md-6">
                <h3
                  className={
                    cookies.get("i18next") === "en"
                      ? "order_id_style_en fw-bold mb-4"
                      : cookies.get("i18next") === "fn"
                        ? "order_id_style_fn fw-bold mb-4"
                        : cookies.get("i18next") === "du"
                          ? "order_id_style_du fw-bold mb-4"
                          : "order_id_style_en fw-bold mb-4"}
                >
                  {t("order")} {order.wosh_order_id}
                </h3>
                <ul className="list-unstyled">
                  {order.products.map((product) => {
                    const itemTotal = product.product_price * product.quantity;
                    const formattedTotal = itemTotal.toFixed(2);
                    return (
                      <li key={product.id} className="row product-item mb-3">
                        <div className="col-5">
                          <p className="m-0 small line_height">
                            {cookies.get("i18next") === "en"
                              ? product.product_name_en
                              : cookies.get("i18next") === "fn"
                                ? product.product_name
                                : cookies.get("i18next") === "du"
                                  ? product.product_name_nl
                                  : product.name}
                          </p>
                          <p className="small">{product.product_price} €</p>
                        </div>
                        <div className="col-3">
                          <p className="d-flex justify-content-evenly align-items-center m-0">
                            {product.quantity}
                          </p>
                        </div>
                        <div className="col-4">
                          <p className="m-0 text-end">{formattedTotal} €</p>
                        </div>
                      </li>
                    );
                  })}
                </ul>

                <hr className="w-100" style={{ border: "1px solid #E7E9FF", margin: "20px auto", opacity: 0.9 }} />
                <ul className="list-unstyled w-100 mx-auto" style={{ fontFamily: "Galano Grotesque Alt", fontSize: "14px", fontWeight: 400, lineHeight: "16.2px", textAlign: "left", color: "#3D4586" }}>
                  <li className="d-flex flex-row justify-content-between mb-2">
                    <span>{t("Service fee")}</span>
                    <span>{serviceFee.toFixed(2)} €</span>
                  </li>
                  <li className="d-flex flex-row justify-content-between mb-2">
                    <span>{t("Subtotal")}</span>
                    <span>{formattedSubTotal.toFixed(2)} €</span>
                  </li>
                  <li className="d-flex flex-row justify-content-between mb-2">
                    <span>{t("Promotions")}</span>
                    <span>{promotionTotal.toFixed(2)} €</span>
                  </li>
                </ul>
                <hr className="w-100" style={{ border: "1px solid #E7E9FF", margin: "20px auto 30px", opacity: "0.9" }} />
                <div className="d-flex flex-row justify-content-between mx-auto" style={{ fontFamily: "Galano Grotesque Alt", fontSize: "18px", fontWeight: 500, lineHeight: "24.3px", color: "#3D4586" }}>
                  <span>Total</span>
                  <span style={{ fontFamily: "Galano Grotesque Alt", fontSize: "12px", fontWeight: 400, lineHeight: "16.2px", color: "#3D4586" }}>
                    (tax incl. {taxIncluded}€)
                  </span>
                  <span>{finalTotal} €</span>
                </div>
              </div>

              <div className="col-md-1"></div>
              <div className="col-md-5">

                <h3 className="checkout-pickup-outer-title">{t("pick-up")}</h3>
                <div className="checkout-pickup">
                  <div className="checkout-pickup-title d-flex justify-content-center align-items-center">
                    <p className="checkout-heading m-0">
                      {order.type ? order.type : order.wosh_order_id}
                    </p>
                  </div>
                  <div className="d-flex flex-column justify-content-center align-items-center p-3">
                    <p>{order.pickup_date}</p>
                    <p className="m-0">{order.pickup_slot}</p>
                  </div>
                </div>

                <h3 className="checkout-pickup-outer-title mt-4">{t("Delivery")}</h3>
                <div className="checkout-pickup">
                  <div className="checkout-pickup-title d-flex justify-content-center align-items-center">
                    <p className="checkout-heading m-0">
                      {order.type ? order.type : order.wosh_order_id}
                    </p>
                  </div>
                  <div className="d-flex flex-column justify-content-center align-items-center p-3">
                    <p>{order.delivery_date}</p>
                    <p className="m-0">{order.delivery_slot}</p>
                  </div>
                </div>
              </div>
            </div>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default OrderDetailsModal;
