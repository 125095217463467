import React from 'react'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import WalletStepII from "../WoshPayement/Step2";
import { faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import { connect } from "react-redux";
import { setPaymentMethod } from "../../../../store/Actions/authActions";
import { compose } from "redux";
import { withTranslation } from "react-i18next";

const WoshWalletTracking = (props) => {
  const { amount, total, onClose, t } = props
  return (
    <>
      <div className='position-absolute top-0' style={{left: '380px'}}>
        <div className="modal-dialog flex-column m-0">
          <div className="modal-content bg-transparent mb-5 border-0">
            <div className="d-flex flex-row align-items-center justify-content-end mb-0 mx-2">
              <FontAwesomeIcon
                onClick={onClose}
                icon={faTimesCircle}
                className="white fs-2 cursor_pointer"
                style={{ border: '6px solid #3D4586', borderRadius: 50, marginRight: -20, marginBottom: -25, zIndex: 1000, backgroundColor: '#3D4586' }}
              />
            </div>
            <div className="modal-body rounded_25px bg-light p-4 d-flex flex-column align-items-center wosh_wallet_tracking mx-auto" style={{ border: '8px solid #3D4586' }}>
              <h4 className="theme_color fw-bold align-self-center text-center fw_semibold mb-4">Wosh Wallet</h4>
              <p className="fw-bold m-0 theme_color theme_green_bg white rounded-pill px-4 py-2">
                {amount?.replace(".", ",")} €
              </p>
              <p className="m-0 mb-3 small fw_semibold theme_color">{t("Available funds")}</p>
              {total > amount ? (
                <p className="small text-center">
                  {t("Sorry you have insufficient credit in your wallet, please add credit to continue or choose another payment method.")}
                </p>
              ) : (
                <p className="small text-center">
                  {t("To proceed with your order, kindly proceed by selecting the 'Select Wallet' option. FurtherMore, you have the option to add extra credits to your account for added convenience")}
                </p>
              )}
              <button
                data-bs-toggle="modal"
                data-bs-target="#payment_modalII"
                data-bs-dismiss="modal"
                aria-hidden="true"
                type="submit"
                className="w-100 fw_medium btn theme_2pxborder theme_color bg-transparent rounded-pill px-2 mt-4 mb-2 ms-auto me-auto d-flex justify-content-center"
              >
                {t("Add money")}
              </button>
            </div>
          </div>
        </div>
      </div>
      <WalletStepII />
    </>
  )
}

export default WoshWalletTracking